



























































































































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/DriverScoreboardController'
import Button from '@/app/ui/components/Button/index.vue'
import { DriverScoreboardConfiguration } from '@/domain/entities/DriverScoreboard'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import EditIconAlt from '@/app/ui/assets/edit_icon_alt.vue'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    Modal,
    LoadingOverlay,
    TextInput,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    EditIconAlt,
  },
})
export default class DriverScoreboardConfigPage extends Vue {
  controller = controller
  modalTitle = ''
  modalData: DriverScoreboardConfiguration[] = []

  created(): void {
    this.fetchConfiguration()

    EventBus.$on(EventBusConstants.UPDATE_DRIVER_SCOREBOARD_SUCCESS, () => {
      Vue.notify({
        title: `Poin Penilaian Driver`,
        text: `Poin Penilaian Behasil Diupdate`,
        type: 'success',
        duration: 5000,
      })

      this.modalTitle = ''
      this.modalData = []
      this.fetchConfiguration()
    })
  }

  get acceptOrder(): DriverScoreboardConfiguration[] {
    return controller.configurationData.filter(
      config => config.scoreType === 'accept_duration'
    )
  }

  get pickup(): DriverScoreboardConfiguration[] {
    return controller.configurationData.filter(
      config => config.scoreType === 'pickup_duration'
    )
  }

  get dropOff(): DriverScoreboardConfiguration[] {
    return controller.configurationData.filter(
      config => config.scoreType === 'done_duration'
    )
  }

  get orderNotAccepted(): DriverScoreboardConfiguration[] {
    return controller.configurationData.filter(config =>
      ['pickup_reject', 'ignore', 'pickup_cancel'].includes(
        config.scoreValue as string
      )
    )
  }

  private fetchConfiguration(): void {
    this.controller.getAll()
  }

  private formatLabel(scoreValue: string): string {
    // eslint-disable-next-line no-useless-escape
    const lessThanOrEqual = /^\<\=([0-9]+)$/
    if (lessThanOrEqual.test(scoreValue)) {
      return scoreValue.replace(
        lessThanOrEqual,
        (_, match) => `< ${match.padStart(2, '0')} Menit`
      )
    }

    // eslint-disable-next-line no-useless-escape
    const between = /^([0-9]+)\>x\<\=([0-9]+)$/
    if (between.test(scoreValue)) {
      return scoreValue.replace(
        between,
        (_, match1, match2) => `> ${match1.padStart(2, '0')} - ${match2} Menit`
      )
    }

    const label: Record<string, string> = {
      sameday: 'Sameday',
      non_sameday: 'Tidak Sameday',
      ignore: 'Diabaikan',
      pickup_cancel: 'Dibatalkan',
      pickup_reject: 'Ditolak',
    }

    if (label[scoreValue]) return label[scoreValue]

    return scoreValue
  }

  private openModal(title: string, data: DriverScoreboardConfiguration[]) {
    this.modalTitle = title
    this.modalData = data
  }

  private toggleAction(id: number) {
    this.modalData = this.modalData.map(data => ({
      ...data,
      action:
        data.id === id
          ? data.action === 'active'
            ? 'deactive'
            : 'active'
          : data.action,
    }))
  }

  private updatePoint(id: number, value: string) {
    this.modalData = this.modalData.map(data => ({
      ...data,
      point: data.id === id ? Number(value) : data.point,
    }))
  }

  private updateConfig() {
    controller.updateConfiguration(this.modalData)
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_DRIVER_SCOREBOARD_SUCCESS)
  }
}
