var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col pb-8"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('h2',{staticClass:"text-2xl font-semibold mb-5 pb-2"},[_vm._v("Poin Penilaian")]),_c('div',{staticClass:"grid gap-10"},[_c('div',[_c('div',{staticClass:"flex justify-between border-b pb-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Terima order")]),_c('div',{staticClass:"text-bgPrimary font-bold flex cursor-pointer",on:{"click":function($event){return _vm.openModal('Terima order', _vm.acceptOrder)}}},[_vm._v(" Edit "),_c('EditIconAlt',{staticClass:"ml-2 self-center"})],1)]),_vm._l((_vm.acceptOrder),function(item){return _c('div',{key:item.id,class:[
          'flex justify-between border-b py-2',
          { deactive: item.action === 'deactive' } ]},[_c('span',[_vm._v(_vm._s(_vm.formatLabel(item.scoreValue)))]),_c('span',[_vm._v(_vm._s(item.point))])])})],2),_c('div',[_c('div',{staticClass:"flex justify-between border-b pb-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Melakukan pickup")]),_c('div',{staticClass:"text-bgPrimary font-bold flex cursor-pointer",on:{"click":function($event){return _vm.openModal('Melakukan pickup', _vm.pickup)}}},[_vm._v(" Edit "),_c('EditIconAlt',{staticClass:"ml-2 self-center"})],1)]),_vm._l((_vm.pickup),function(item){return _c('div',{key:item.id,class:[
          'flex justify-between border-b py-2',
          { deactive: item.action === 'deactive' } ]},[_c('span',[_vm._v(_vm._s(_vm.formatLabel(item.scoreValue)))]),_c('span',[_vm._v(_vm._s(item.point))])])})],2),_c('div',[_c('div',{staticClass:"flex justify-between border-b pb-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Dropoff")]),_c('div',{staticClass:"text-bgPrimary font-bold flex cursor-pointer",on:{"click":function($event){return _vm.openModal('Dropoff', _vm.dropOff)}}},[_vm._v(" Edit "),_c('EditIconAlt',{staticClass:"ml-2 self-center"})],1)]),_vm._l((_vm.dropOff),function(item){return _c('div',{key:item.id,class:[
          'flex justify-between border-b py-2',
          { deactive: item.action === 'deactive' } ]},[_c('span',[_vm._v(_vm._s(_vm.formatLabel(item.scoreValue)))]),_c('span',[_vm._v(_vm._s(item.point))])])})],2),_c('div',[_c('div',{staticClass:"flex justify-between border-b pb-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Order tidak diterima")]),_c('div',{staticClass:"text-bgPrimary font-bold flex cursor-pointer",on:{"click":function($event){return _vm.openModal('Order tidak diterima', _vm.orderNotAccepted)}}},[_vm._v(" Edit "),_c('EditIconAlt',{staticClass:"ml-2 self-center"})],1)]),_vm._l((_vm.orderNotAccepted),function(item){return _c('div',{key:item.id,class:[
          'flex justify-between border-b py-2',
          { deactive: item.action === 'deactive' } ]},[_c('span',[_vm._v(_vm._s(_vm.formatLabel(item.scoreValue)))]),_c('span',[_vm._v(_vm._s(item.point))])])})],2),_c('div',[_c('div',{staticClass:"flex justify-between border-b border-t py-2"},[_c('span',{staticClass:"font-bold text-xl"},[_vm._v("List Kurir")]),_c('router-link',{staticClass:"text-bgPrimary font-bold self-center",attrs:{"to":{ name: 'DriverScoreboardList' }}},[_vm._v("Lihat")])],1)])]),_c('Modal',{staticClass:"long-modal",attrs:{"visible":!!_vm.modalTitle,"title":_vm.modalTitle}},[_c('div',{attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('table',{staticClass:"w-full table-data"},[_c('tr',[_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Aktifasi")]),_c('th',{staticClass:"text-left pl-2"},[_vm._v(" "+_vm._s(_vm.modalTitle === 'Order tidak diterima' ? 'Aksi' : 'Durasi')+" ")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Nilai")])]),_vm._l((_vm.modalData),function(data){return _c('tr',{key:data.id},[_c('td',[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleAction(data.id)}}},[(data.action === 'active')?_c('CheckBoxCheckedIcon',{staticClass:"fill-current text-bgPrimary mx-auto"}):_c('CheckBoxUncheckedIcon',{staticClass:"fill-current text-bgPrimary mx-auto"})],1)]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.formatLabel(data.scoreValue)))]),_c('td',[_c('input',{staticClass:"point-value",attrs:{"type":"number"},domProps:{"value":data.point},on:{"change":function (event) { return _vm.updatePoint(data.id, event.target.value); }}})])])})],2)]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"mr-3",attrs:{"buttonStyle":"outline"},on:{"click":function () {
            _vm.modalTitle = ''
            _vm.modalData = []
          }}},[_vm._v("Batal")]),_c('Button',{on:{"click":_vm.updateConfig}},[_vm._v("Simpan")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }